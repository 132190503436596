<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                <div class="card-icon">
                    <md-icon>link</md-icon>
                </div>
                <h4 class="title">Embed code</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-field>
                                <label>Embed code(copy)</label>
                                    <md-textarea v-model="embedCode" readonly></md-textarea>
                                <md-icon>description</md-icon>
                            </md-field>
                        </div>
                        
                        <!-- <div class="md-layout-item md-size-60">
                            <md-field>
                                <label>user email</label>
                                <md-input :disabled="true" v-model="user.email"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-60">
                            <md-field>
                                <label>{{ oldPasswordLabel }}</label>
                                <md-input type="password" :disabled="oldPasswordDisabled" v-model="password"></md-input>
                            </md-field>
                        </div>
                        <div v-if="oldPasswordDisabled" class="md-layout-item md-size-40">
                            <md-button @click="openChangePassword" class="md-dense md-raised md-primary">변경</md-button>
                        </div>
                        <div v-if="!oldPasswordDisabled" class="md-layout-item md-size-60">
                            <md-field>
                                <label>new password</label>
                                <md-input type="password" @keyup="passwordValid" v-model="newPassword"></md-input>
                            </md-field>
                        </div>
                        <div v-if="!oldPasswordDisabled" class="md-layout-item md-size-60">
                            <md-field>
                                <label>re password</label>
                                <md-input type="password" @keyup="passwordValid" v-model="rePassword"></md-input>
                            </md-field>
                        </div>
                        <div v-if="!oldPasswordDisabled" class="md-layout-item md-size-40">
                            <md-button :disabled="passwordBtnDisabled" @click="changePassword" class="md-dense md-raised md-primary">저장</md-button>
                        </div> -->
                    </div>
                </md-card-content>
            </md-card>
            <md-card>
                <md-card-content>
                    <div class="md-layout md-alignment-center-center">
                        <p @click="copy" class="logout-btn">copy</p>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";
  import { mapActions } from 'vuex';

export default {
    data() {
        return {
            user: {},
            password: "",
            newPassword: "",
            rePassword: "",
            oldPasswordDisabled: true,
            oldPasswordLabel: "user password",
            passwordBtnDisabled: false,
            embedCode: "<div style='position: relative'>\n\t<iframe style='position:absolute;top:0;left:0;width:100%;height:100%;border:none' src='https://dev-fashion.360xcon.com/chanel' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'></iframe>\n</div>"
        }
    },
    async created() {
        const res = await axios.get(api.GET_USER_INFO()
            ,{ 
                headers: { 
                    authorization: this.$store.getters.getToken,
                }
            });
        if(!res.data.success) {
            return;
        }
        
        this.user = res.data.data;
    },
    methods: {
        ...mapActions([
            "logoutAdmin"
        ]),
        openChangePassword() {
            this.oldPasswordLabel = "old password";
            this.oldPasswordDisabled = false;
            this.passwordBtnDisabled = true;
        },
        passwordValid() {
            if(this.newPassword === this.rePassword) {
                this.passwordBtnDisabled = false;
            }
            else {
                this.passwordBtnDisabled = true;
            }
        },
        async changePassword() {
            const result = await this.axios.put(api.UPDATE_USER_PW(), {
                oldPassword: this.password,
                newPassword: this.rePassword
            },
            { 
                headers: { 
                    authorization: this.$store.getters.getToken,
                }
            });

            const success = result.data.success;
            if(success) {
                return this.$store.dispatch("alerts/success", "비밀번호가 변경되었습니다.");
            }

            return  this.$store.dispatch("alerts/error", "비밀번호 변경 실패");
        },
        async logout() {
            const result = await this.logoutAdmin();
            if(result) {
                this.$store.dispatch("alerts/success", "로그아웃 되었습니다.");
                
                return this.$router.push("/login");
            }

            return this.$store.dispatch("alerts/error", "로그아웃 실패");
        },
        async copy() {
            window.navigator.clipboard.writeText(this.embedCode).then(() => {
                this.$store.dispatch("alerts/success", "success to copy!");
            });
        }
    }
}
</script>

<style scoped>
.logout-btn {
    color: red;
    cursor: pointer;
    font-weight: 500;
    margin: 0px;
}
</style>